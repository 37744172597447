<template>
    <div class="datafeed-content">
        <div class="datafeed-content__left">
            <div class="datafeed-content__left__inner">
                <VehiclesList/>
            </div>
        </div>
        <div class="datafeed-content__right">
            <div class="datafeed-content__right__inner">
                <div v-if="!empty" class="dygraph-list">
                    <DygraphTemperature :options="options.primary" :vehicle="current" height="320"/>
                    <DygraphTemperature v-for="child in children" :key="child.id" height="300"
                                        :options="getSecondaryOptions(child.id)" :vehicle="child.id"/>
                </div>
                <!--<div v-else>
                    <span v-if="current">Keine Sensoren konfiguriert.</span>
                    <span v-else>Kein Fahrzeug ausgewählt.</span>
                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import store from '@/store/index';
    import VehiclesList from "@/components/VehiclesList";
    import DygraphTemperature from '@/components/DygraphTemperature';
    import Dygraphs from 'dygraphs';

    export default {
        name: "Dashboard",
        data() {
            return {
                options: {
                    primary: {
                        colors: [
                            '#00557A',
                            '#C28B00'
                        ],
                        legend: 'always',
                        connectSeparatedPoints: false,
                        showRangeSelector: true,
                        rangeSelectorForegroundStrokeColor: '#002C3F',
                        rangeSelectorBackgroundStrokeColor: '#00425F',
                        rangeSelectorPlotFillColor: '',
                        rangeSelectorPlotFillGradientColor: '',
                        rangeSelectorPlotLineWidth: 1,
                    },
                    secondary: {},
                },
                secondaryOptionsTemplate: {
                    colors: [
                        '#00557A',
                        '#C28B00'
                    ],
                    legend: 'always',
                    connectSeparatedPoints: false,
                },
            };
        },
        components: {
            VehiclesList,
            DygraphTemperature,
        },
        computed: {
            children: function () {
                let children = [];

                if (this.vehicles[this.current].children.length) {
                    for (let j in this.vehicles[this.current].children) {
                        if (Object.prototype.hasOwnProperty.call(this.vehicles[this.current].children, j)) {
                            children.push({id: this.vehicles[this.current].children[j].id.toString()});
                        }
                    }
                }

                return children;
            },
            empty: function () {
                return !this.current || !Object.prototype.hasOwnProperty.call(this.sensors, this.current) || !this.children.length
            },
            ...mapGetters('vehicle', ['vehicles', 'current']),
            ...mapGetters('sensor', ['loading', 'error', 'sensors']),
            ...mapGetters('temperature', ['temperatures']),
        },
        methods: {
            getSecondaryOptions: function (vehicle) {
                if (!Object.prototype.hasOwnProperty.call(this.options.secondary, vehicle)) {
                    this.options.secondary[vehicle] = Object.assign({}, this.secondaryOptionsTemplate);
                }

                return this.options.secondary[vehicle];
            },
            getSensors: function () {
                if (!this.current || Object.prototype.hasOwnProperty.call(this.sensors, this.current)) {
                    return;
                }

                store.dispatch('sensor/getData', {
                    current: this.current,
                    children: this.vehicles[this.current].children
                });
            },
            setOptions: function () {
                if (!this.current || !Object.prototype.hasOwnProperty.call(this.sensors, this.current)) {
                    return;
                }

                let labels = ['Temp'];

                for (let i in this.sensors[this.current]) {
                    if (Object.prototype.hasOwnProperty.call(this.sensors[this.current], i)) {
                        labels.push(this.sensors[this.current][i].label);
                    }
                }

                this.options.primary = Object.assign(
                    {},
                    this.options.primary,
                    {labels: labels}
                );

                if (this.vehicles[this.current].children.length) {
                    for (let j in this.vehicles[this.current].children) {
                        if (Object.prototype.hasOwnProperty.call(this.vehicles[this.current].children, j)) {
                            let child = this.vehicles[this.current].children[j].id.toString();
                            let labels = ['Temp'];

                            if (Object.prototype.hasOwnProperty.call(this.sensors, child)) {
                                for (let i in this.sensors[child]) {
                                    if (Object.prototype.hasOwnProperty.call(this.sensors[child], i)) {
                                        labels.push(this.sensors[child][i].label);
                                    }
                                }

                                this.options.secondary[child] = Object.assign(
                                    {},
                                    this.secondaryOptionsTemplate,
                                    {labels: labels}
                                );
                            }
                        }
                    }
                }
            },
            getData: function () {
                let min = new Date();
                min.setUTCHours(0, 0, 0, 0);
                min = min.getTime();

                let max = new Date(min);
                max.setUTCHours(23, 59, 59, 0);

                min = min / 1000;
                max = max.getTime() / 1000;

                if (Object.prototype.hasOwnProperty.call(this.sensors, this.current)) {
                    if (!Object.prototype.hasOwnProperty.call(this.temperatures, this.current)) {
                        this.getDataForVehicle(this.current, min, max);
                    }
                }

                if (this.vehicles[this.current].children.length) {
                    for (let j in this.vehicles[this.current].children) {
                        if (Object.prototype.hasOwnProperty.call(this.vehicles[this.current].children, j)) {
                            let child = this.vehicles[this.current].children[j].id.toString();

                            if (Object.prototype.hasOwnProperty.call(this.sensors, child)) {
                                if (!Object.prototype.hasOwnProperty.call(this.temperatures, child)) {
                                    this.getDataForVehicle(child, min, max);
                                }
                            }
                        }
                    }
                }
            },
            getDataForVehicle: function (vehicle, min, max) {
                let tags = [];

                for (let i in this.sensors[vehicle]) {
                    if (Object.prototype.hasOwnProperty.call(this.sensors[vehicle], i)) {
                        tags.push(this.sensors[vehicle][i].tagID);
                    }
                }

                store.dispatch('temperature/getData', {
                    vehicle: vehicle,
                    tags: tags,
                    min: min,
                    max: max
                });
            },
        },
        created: function () {
            this.getSensors();
            this.setOptions();

            // @todo the following is beyond stupid, gotta change it asap!

            window.DygraphCharts = window.DygraphCharts || [];
            window.DygraphChartsSync = window.DygraphChartsSync || 0;

            const TheDygraph = Dygraphs;

            function syncChartsIfAvailable() {
                if (window.DygraphCharts.length > 1 && window.DygraphCharts.length > window.DygraphChartsSync) {
                    TheDygraph.synchronize(window.DygraphCharts, {
                        selection: true,
                        range: false,
                        zoom: true
                    });

                    window.DygraphChartsSync = window.DygraphCharts.length;
                }

                window.setTimeout(syncChartsIfAvailable, 100);
            }

            syncChartsIfAvailable();
        },
        watch: {
            current: function () {
                // @todo the following is beyond stupid, gotta change it asap!

                window.DygraphCharts = [];
                window.DygraphChartsSync = 0;

                this.getSensors();
                this.setOptions();
                this.getData();
            },
            sensors: function () {
                this.setOptions();
                this.getData();
            },
        },
    };
</script>
