<template>
    <div class="vehicles-list">
        <h2>Ihre Fahrzeuge</h2>

        <a href="#" v-for="vehicle in vehicles" :key="vehicle.id"
           v-on:click.prevent="selectVehicle(vehicle.id)"
           :class="{active: current === vehicle.id}">

            <TruckIcon/>

            <span class="car-item">{{ vehicle.plate }}</span>

            <span v-for="child in vehicle.children" :key="child.id" class="car-item-child">
                {{ child.plate }}
            </span>
        </a>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import store from '@/store/index';
    import TruckIcon from "@/components/icons/TruckIcon";

    export default {
        name: "VehiclesList",
        components: {
            TruckIcon,
        },
        computed: {
            ...mapGetters('vehicle', ['loading', 'error', 'vehicles', 'current']),
        },
        methods: {
            selectVehicle: function (id) {
                store.dispatch('vehicle/setCurrent', id);
            },
        },
        created: function () {
            if (typeof this.vehicles === 'object' && Object.keys(this.vehicles).length) {
                return;
            }

            store.dispatch('vehicle/getData');
        },
    };
</script>

<style lang="scss">
    .vehicles-list {
        h2 {
            margin-bottom: $main-padding;
        }

        a {
            color: $black-text;
            text-decoration: none;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            padding: 10px 0;
            border-bottom: $default-border;

            &:last-of-type {
                border-bottom: none;
            }

            &:hover,
            &.active {
                color: $primary-color;
                text-decoration: none;
            }

            .car-item {
                margin-left: 15px;
                font-size: $font-size-big;
            }

            .car-item-child {
                width: 100%;
                margin-top: 5px;
                margin-left: 40px;
                font-size: $font-size-normal;
            }
        }
    }
</style>
