<template>
    <div class="dygraph-wrap">
        <div v-if="graph === null || loading || vehicleLoading || sensorLoading" class="dygraph-placeholder"></div>

        <div :id="'vue-dygraphs-' + vehicle" :style="'width: 100%; height: ' + height + 'px'"></div>
    </div>
</template>

<script>
    import {mapGetters} from 'vuex';
    import Dygraphs from 'dygraphs';

    export default {
        data() {
            return {
                graph: null,
            }
        },
        mounted: function () {
            if (Object.prototype.hasOwnProperty.call(this.temperatures, this.vehicle)) {
                this.renderGraph();
            }
        },
        computed: {
            ...mapGetters('temperature', ['loading', 'temperatures']),
            ...mapGetters({vehicleLoading: 'vehicle/loading'}),
            ...mapGetters({sensorLoading: 'sensor/loading'}),
        },
        props: {
            vehicle: {
                type: String,
                default() {
                    return '';
                },
            },
            options: {
                type: Object,
                default() {
                    return {};
                },
            },
            height: {
                type: Number,
                default() {
                    return 500;
                },
            },
        },
        methods: {
            renderGraph() {
                const elm = 'vue-dygraphs-' + this.vehicle;
                this.graph = new Dygraphs(elm, this.temperatures[this.vehicle], this.options);

                // @todo the following is beyond stupid, gotta change it asap! see dashboard.vue

                window.DygraphCharts = window.DygraphCharts || [];
                window.DygraphCharts.push(this.graph);
            },
            updateGraph(withData = false) {
                if (this.graph === null) {
                    return;
                }

                let options = Object.assign({}, this.options, {dateWindow: null, valueRange: null});

                if (withData) {
                    options = Object.assign(options, {file: this.temperatures[this.vehicle]});
                }

                this.graph.updateOptions(options);
            },
        },
        watch: {
            vehicle: function () {
                this.updateGraph(true);

                // @todo the following is beyond stupid, gotta change it asap! see dashboard.vue

                window.DygraphCharts = window.DygraphCharts || [];
                window.DygraphCharts.push(this.graph);
            },
            options: function () {
                this.updateGraph();
            },
            temperatures: function (value) {
                if (!Object.prototype.hasOwnProperty.call(value, this.vehicle)) {
                    return;
                }

                if (this.graph === null) {
                    this.renderGraph();
                } else {
                    this.updateGraph(true);
                }
            },
        },
    }
</script>

<style lang="scss">
    @keyframes fade {
        0%   { background-color: #ffffff; }
        100% { background-color: $light-grey; }
    }

    .dygraph-wrap {
        position: relative;
        margin-right: $main-padding;

        + .dygraph-wrap {
            margin-top: $main-padding;
        }

        .dygraph-placeholder {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            animation: fade .4s infinite ease-in-out;
            z-index: 11;

            &::after {
                content: 'loading...';
                display: block;
                width: auto;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: #ffffff;
            }
        }
    }
</style>
